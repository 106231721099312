export default {
  notifications: {
    types: {
      error: 'Error',
      success: 'Success',
      warning: 'Warning',
      system: 'System',
      forbidden: 'Forbidden',
    },
  },
  app: {
    settings: 'Settings',
    details: 'Details',
    export: 'Export',
  },
  fields: {
    messages: {
      required: 'Required.',
      email: 'Please enter a valid email address.',
      number: 'Please enter a valid number.',
      maxLength: 'Please enter no more than {{max}} characters.',
      minLength: 'Please enter at least {{min}} characters.',
      equalTo: 'Please enter the same value again.',
      url: 'Please enter a valid URL.',
      date: 'Please enter a valid date ({{format}}).',
    },
  },
  errors: {
    500: 'Something went wrong, try again later.',
    403: 'Email address and/or password is incorrect.',
  },
  status: {
    active: 'Active',
    pending: 'Pending',
    inactive: 'Inactive',
    deleted: 'Deleted',
  },
  roles: {
    admin: 'Admin',
    user: 'User',
  },
  nav: {
    investments: 'Investments',
    clients: 'Clients',
    campaigns: 'Campaigns',
    sources: 'Sources',
    reports: 'Reports',
    users: 'Users',
    channels: 'Channels',
  },
  login: {
    title: 'Login',
    description: 'Sign In to your account',
    btn: 'Login',
    forgotPassword: 'Forgot password?',
    signUp: 'Sign Up',
    email: 'Email',
    password: 'Password',
  },
  register: {
    title: 'Register',
    description: 'Create your account',
    btn: 'Create Account',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    errors: {
      409: 'User already exists.',
    },
    success: 'User created successfully. A verification email has been sent to your email account.',
  },
  recover: {
    title: 'Recover',
    description: 'Recover account password',
    btn: 'Recover',
    email: 'Email',
    errors: {
      404: 'User account not found.',
    },
    success: 'A reset email has been sent to your email account.',
  },
  reset: {
    title: 'Reset',
    description: 'Reset account password',
    btn: 'Reset',
    password: 'New Password',
    confirmPassword: 'Confirm New Password',
    login: 'Login',
    errors: {
      400: 'Password reset token is invalid or has expired.',
    },
    success: 'Password has been updated. Please log in.',
  },
  verify: {
    login: 'Login',
    errors: {
      400: 'Account verify token is invalid or has expired.',
    },
    success: 'The account has been verified. Please log in.',
  },
  layoutHeader: {
    logout: 'Logout',
    profile: 'Profile',
    settings: 'Settings',
  },
  layoutFooter: {
    brand: 'Performance Sales',
    powered: 'Powered by',
    settings: 'Settings',
  },
  campaigns: {
    header: 'Campaigns List',
    id: '#',
    name: 'Name',
    budget: 'Budget',
    payout: 'Payout',
    status: 'Status',
    startDate: 'Start',
    endDate: 'End',
    new: 'New Campaign',
    createdAt: 'Created',
    updatedAt: 'Updated',
    noResults: 'No results found.',
  },
  campaignsModal: {
    header: 'Campaign',
    name: 'Name',
    client: 'Client',
    clientSelect: 'Select one client',
    budget: 'Budget',
    payout: 'Payout',
    status: 'Status',
    statusSelect: 'Select one status',
    startDate: 'Start',
    endDate: 'End',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  campaignsDetail: {
    budget: 'Budget',
    payout: 'Payout',
    campaign: 'Campaign',
    manager: 'Project Manager',
    noResults: 'No project manager found.',
  },
  sources: {
    header: 'Sources List',
    id: '#',
    name: 'Name',
    status: 'Status',
    new: 'New Source',
    createdAt: 'Created',
    updatedAt: 'Updated',
    noResults: 'No results found.',
  },
  sourcesModal: {
    header: 'Source',
    name: 'Name',
    status: 'Status',
    statusSelect: 'Select one status',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  users: {
    header: 'Users List',
    id: '#',
    name: 'Name',
    email: 'Email',
    role: 'Role',
    status: 'Status',
    new: 'New User',
    lastLoginDate: 'Last Login',
    createdAt: 'Created',
    updatedAt: 'Updated',
  },
  usersModal: {
    header: 'User',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    role: 'Role',
    roleSelect: 'Select one role',
    status: 'Status',
    statusSelect: 'Select one status',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    clients: 'Clients',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  clients: {
    header: 'Clients List',
    id: '#',
    name: 'Name',
    site: 'Site',
    status: 'Status',
    new: 'New Client',
    createdAt: 'Created',
    updatedAt: 'Updated',
    noResults: 'No results found.',
  },
  clientsModal: {
    header: 'Client',
    name: 'Name',
    status: 'Status',
    statusSelect: 'Select one status',
    site: 'Site',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  investments: {
    header: 'Investments List',
    id: '#',
    date: 'Date',
    campaignName: 'Campaign',
    sourceName: 'Source',
    channelName: 'Channel',
    clicks: 'Clicks',
    leads: 'Leads',
    impressions: 'Impressions',
    cpl: 'CPL',
    revenue: 'Revenue',
    cost: 'Cost',
    cm: 'CM',
    status: 'Status',
    new: 'New Investment',
    createdAt: 'Created',
    updatedAt: 'Updated',
    noResults: 'No results found.',
  },
  investmentsModal: {
    header: 'Investment',
    date: 'Date',
    clicks: 'Clicks',
    leads: 'Leads',
    impressions: 'Impressions',
    cpl: 'CPL',
    revenue: 'Revenue',
    cost: 'Cost',
    cm: 'CM',
    campaign: 'Campaign',
    campaignSelect: 'Select one campaign',
    source: 'Source',
    sourceSelect: 'Select one source',
    channel: 'Channel',
    channelSelect: 'Select one channel',
    status: 'Status',
    statusSelect: 'Select one status',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  pagination: {
    next: 'Next',
    prev: 'Prev',
  },
  channels: {
    header: 'Channels List',
    id: '#',
    name: 'Name',
    status: 'Status',
    new: 'New Channel',
    createdAt: 'Created',
    updatedAt: 'Updated',
    noResults: 'No results found.',
  },
  channelsModal: {
    header: 'Channel',
    name: 'Name',
    status: 'Status',
    statusSelect: 'Select one status',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
  },
  search: {
    placeholder: 'Search...',
  },
  filters: {
    client: 'Client',
    campaign: 'Campaign',
    source: 'Source',
    channel: 'Channel',
    startDate: 'Start Date',
    endDate: 'End Date',
    filters: 'Filters',
    clear: 'Clear',
  },
  loading: 'Loading...',
  page404: {
    number: 404,
    title: "Oops! You're lost.",
    desc: 'The page you are looking for was not found.',
  },
};
